<script>
    import OverlayLink from "@/components/OverlayLink.svelte";
    import localizer from "@/common/i18n.js";

    let { privacyPolicy } = $props();

    const t = localizer({
        'nb-NO': {
            privacy: 'Personvern',
        },
        'da-DK': {
            privacy: 'Privatliv',
        }
    })
</script>

<p class="privacy-policy">
    <span>Les vår</span>
    <OverlayLink header={t('privacy')} label="personvernerklæring">
        {@html privacyPolicy}
    </OverlayLink>.
</p>

<style >
    p {
        display: flex;
        flex-direction: row;
    }

    span {
        padding-right: .2em;
    }
</style>